import React from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import Paper from '@material-ui/core/Paper';
import { withRouter } from 'react-router-dom';
import { Drawer } from '@material-ui/core';
import TableFieldMeta from '../../common/TableFieldMeta';
import { useCommonStyles } from '../common/Styles';
import MultiCurrencyValue from '../common/MultiCurrencyValue';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import TablePaginator from '../common/TablePaginator';
import CashActivityDetails from './CashActivityDetails';
import MoreButton from '../common/buttons/MoreButton';
import AdminMetaContext from '../adminPanel/AdminMetaContext';

function CashActivityList(props) {
  const { viewer, relay, filter } = props;
  filter.setRelay(relay);
  const classes = useCommonStyles();
  const [showDetails, setShowDetails] = React.useState(false);
  const [members] = React.useState({
    detailShownCashSummary: null,
  });

  const adminMeta = React.useContext(AdminMetaContext);

  const columns = [
    TableFieldMeta.localId,
    TableFieldMeta.field('description', 'Description'),
    TableFieldMeta.dateField('reportDate', 'Report Date'),
    TableFieldMeta.dateTimeField('summaryDatetime', 'Summary Time'),
    {
      accessor: node => (
        <MultiCurrencyValue
          valueNode={node.cashDeltas}
        />
      ),
      Header: 'Amount',
      id: 'amount',
      ...TableFieldMeta.rightAlignStyle,
    },
    {
      accessor: node => (
        <MultiCurrencyValue
          valueNode={node.cashBalances}
        />
      ),
      Header: 'Ending Cash',
      id: 'endingCash',
      ...TableFieldMeta.rightAlignStyle,
    },
    TableFieldMeta.priceField('endingCashBase', 'Ending Cash (Base)', 'endingCashBase', () => adminMeta.baseCurrency, 2),
    {
      id: 'more',
      accessor: node => node,
      Header: '',
      Cell: row => (
        <MoreButton
          onClick={() => {
            members.detailShownCashSummary = row.value.id;
            setShowDetails(true);
          }}
        />
      ),
    },
  ];

  return (
    <Paper className={classes.innerTabRoot}>
      <Drawer
        anchor="right"
        open={showDetails}
        onClose={() => setShowDetails(false)}
      >
        <div className={classes.drawerRoot}>
          <CashActivityDetails
            cashSummaryId={members.detailShownCashSummary}
          />
        </div>
      </Drawer>
      <TablePaginator
        columns={columns}
        filter={filter}
        getDataNode={() => (viewer ? viewer.activity : null)}
      />
    </Paper>
  );
}

const query = graphql`
  query CashActivityListQuery(
    $first: Int,
    $offset: Int,
    $accountId: ID
  )  {
    viewer {
      ...CashActivityList_viewer
      @arguments(
        first: $first,
        offset: $offset,
        accountId: $accountId
      )
    }
  }
`;

const container = createRefetchContainer(
  CashActivityList,
  {
    viewer: graphql`
      fragment CashActivityList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10}
        offset: {type: Int}
        accountId: {type: ID}
      )
      {
        activity(
          accountId: $accountId
          first: $first
          offset: $offset
          orderBy: "-createdAt"
        ) {
          edges {
            node {
              id
              summaryDatetime
              reportDate
              description
              cashDeltas {
                edges {
                  node {
                    quantity
                    currency
                    createdAt
                    updatedAt
                  }
                }
              }
              cashBalances {
                edges {
                  node {
                    id
                    currency
                    quantity
                  }
                }
              }
              endingCashBase
            }
          }
          count
        }
      }
    `,
  },
  query,
);

export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  {},
  ({ accountId }) => ({ accountId }),
));
