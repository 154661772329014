/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
export type FeeType = "ADE" | "ADF" | "ASB" | "AVH" | "BUF" | "CHI" | "CHIP" | "CHUP" | "COR" | "CRDT" | "FXB" | "GST" | "INT" | "MGN" | "MSB" | "PLF" | "PLT" | "PRO" | "RTA" | "RTP" | "STB" | "STBV" | "TAX" | "TRA" | "VHF" | "WDR" | "%future added value";
export type OrderStatus = "CAN" | "CRE" | "FIL" | "INI" | "INM" | "PEX" | "PFL" | "POS" | "QUE" | "REJ" | "%future added value";
export type OrderType = "LMT" | "MKT" | "SLMT" | "SMKT" | "TLMT" | "TMKT" | "%future added value";
export type Side = "BUY" | "SELL" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type FxOrderDetails_fxOrder$ref: FragmentReference;
declare export opaque type FxOrderDetails_fxOrder$fragmentType: FxOrderDetails_fxOrder$ref;
export type FxOrderDetails_fxOrder = {|
  +id: string,
  +price: ?number,
  +quantity: ?number,
  +createdAt: any,
  +updatedAt: any,
  +quantityToDate: ?number,
  +contract: {|
    +id: string,
    +currency: Currency,
    +symbol: string,
    +localSymbol: string,
    +ibConId: number,
  |},
  +orderPtr: {|
    +account: {|
      +id: string,
      +name: ?string,
    |},
    +orderType: OrderType,
    +executionSet: {|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +transaction: ?{|
            +id: string
          |},
          +price: number,
          +proceeds: ?number,
          +executionDatetime: any,
          +quantity: number,
          +currency: Currency,
        |}
      |}>
    |},
    +transactions: {|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +commission: ?number,
          +tax: ?number,
          +transactionaccountchargeSet: {|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +amount: number,
                +currency: Currency,
                +feeType: FeeType,
                +accountchargePtr: {|
                  +id: string,
                  +amount: number,
                  +currency: Currency,
                  +accountchargetenantportionSet: {|
                    +edges: $ReadOnlyArray<?{|
                      +node: ?{|
                        +id: string,
                        +quantity: number,
                        +tenant: ?{|
                          +name: string,
                          +id: string,
                        |},
                      |}
                    |}>
                  |},
                |},
              |}
            |}>
          |},
        |}
      |}>
    |},
  |},
  +brokerOrder: ?{|
    +id: string,
    +twsOrderId: ?number,
    +twsPermId: ?number,
    +cashQuantity: ?number,
    +quantityFilled: ?number,
    +quantityLeft: ?number,
    +stopPrice: ?number,
    +trailStopRatio: ?number,
    +trailStopOffset: ?number,
    +lastSyncTime: ?any,
    +status: OrderStatus,
    +orderRef: ?string,
    +side: Side,
    +orderType: OrderType,
    +ibExchange: ?{|
      +id: string,
      +symbol: string,
    |},
    +brokerorderamendmentSet: {|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +quantity: number,
          +previousQuantity: number,
          +previousLimitPrice: ?number,
          +limitPrice: ?number,
          +amendmentRef: ?string,
        |}
      |}>
    |},
  |},
  +$refType: FxOrderDetails_fxOrder$ref,
|};
export type FxOrderDetails_fxOrder$data = FxOrderDetails_fxOrder;
export type FxOrderDetails_fxOrder$key = {
  +$data?: FxOrderDetails_fxOrder$data,
  +$fragmentRefs: FxOrderDetails_fxOrder$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "price",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "currency",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "symbol",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "orderType",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "amount",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "FxOrderDetails_fxOrder",
  "type": "FxOrderNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "createdAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "updatedAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "quantityToDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "contract",
      "storageKey": null,
      "args": null,
      "concreteType": "FxContractNode",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "localSymbol",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "ibConId",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "orderPtr",
      "storageKey": null,
      "args": null,
      "concreteType": "OrderNode",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "account",
          "storageKey": null,
          "args": null,
          "concreteType": "AccountNode",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v5/*: any*/)
          ]
        },
        (v6/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "executionSet",
          "storageKey": null,
          "args": null,
          "concreteType": "ExecutionNodeConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "ExecutionNodeEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ExecutionNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "transaction",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "TransactionNode",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/)
                      ]
                    },
                    (v1/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "proceeds",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "executionDatetime",
                      "args": null,
                      "storageKey": null
                    },
                    (v2/*: any*/),
                    (v3/*: any*/)
                  ]
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "transactions",
          "storageKey": null,
          "args": null,
          "concreteType": "TransactionNodeConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "TransactionNodeEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "TransactionNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "commission",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "tax",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "transactionaccountchargeSet",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "TransactionAccountChargeNodeConnection",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "edges",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "TransactionAccountChargeNodeEdge",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "node",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "TransactionAccountChargeNode",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                (v7/*: any*/),
                                (v3/*: any*/),
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "feeType",
                                  "args": null,
                                  "storageKey": null
                                },
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "accountchargePtr",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "AccountChargeNode",
                                  "plural": false,
                                  "selections": [
                                    (v0/*: any*/),
                                    (v7/*: any*/),
                                    (v3/*: any*/),
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "accountchargetenantportionSet",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "AccountChargeTenantPortionNodeConnection",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "kind": "LinkedField",
                                          "alias": null,
                                          "name": "edges",
                                          "storageKey": null,
                                          "args": null,
                                          "concreteType": "AccountChargeTenantPortionNodeEdge",
                                          "plural": true,
                                          "selections": [
                                            {
                                              "kind": "LinkedField",
                                              "alias": null,
                                              "name": "node",
                                              "storageKey": null,
                                              "args": null,
                                              "concreteType": "AccountChargeTenantPortionNode",
                                              "plural": false,
                                              "selections": [
                                                (v0/*: any*/),
                                                (v2/*: any*/),
                                                {
                                                  "kind": "LinkedField",
                                                  "alias": null,
                                                  "name": "tenant",
                                                  "storageKey": null,
                                                  "args": null,
                                                  "concreteType": "TenantNode",
                                                  "plural": false,
                                                  "selections": [
                                                    (v5/*: any*/),
                                                    (v0/*: any*/)
                                                  ]
                                                }
                                              ]
                                            }
                                          ]
                                        }
                                      ]
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "brokerOrder",
      "storageKey": null,
      "args": null,
      "concreteType": "BrokerOrderNode",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "twsOrderId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "twsPermId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "cashQuantity",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "quantityFilled",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "quantityLeft",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "stopPrice",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "trailStopRatio",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "trailStopOffset",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "lastSyncTime",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "status",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "orderRef",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "side",
          "args": null,
          "storageKey": null
        },
        (v6/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "ibExchange",
          "storageKey": null,
          "args": null,
          "concreteType": "IBExchangeNode",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v4/*: any*/)
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "brokerorderamendmentSet",
          "storageKey": null,
          "args": null,
          "concreteType": "BrokerOrderAmendmentNodeConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "BrokerOrderAmendmentNodeEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "BrokerOrderAmendmentNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v2/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "previousQuantity",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "previousLimitPrice",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "limitPrice",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "amendmentRef",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '9ecaba808461cb4b819a8b46c181ad45';
module.exports = node;
