import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useCommonStyles } from '../common/Styles';
import createDepositRequestMutation from '../../mutations/CreateDepositRequestMutation';
import { AddButton } from '../common/buttons/BaseButton';
import PopupAccountSelectorField from '../common/PopupAccountSelectorField';
import { doCommitPromise } from '../../common/commit';
import DrawerGridItemContainer from '../common/drawer/DrawerGridItemContainer';
import DrawerGridItemHeading from '../common/drawer/DrawerGridItemHeading';
import DrawerGridItemSelectField from '../common/drawer/DrawerGridItemSelectField';
import DrawerGridItemTextField from '../common/drawer/DrawerGridItemTextField';
import DrawerGridItemBottomButtonContainer
  from '../common/drawer/DrawerGridItemBottomButtonContainer';
import AdminMetaContext from '../adminPanel/AdminMetaContext';

export default function AddDepositForm(props) {
  const {
    onClose,
    onAdd,
    account: loadedAccount,
    custodian: inCustodian,
  } = props;
  const adminMeta = React.useContext(AdminMetaContext);
  const [currency, setCurrency] = React.useState(adminMeta.baseCurrency);
  const [account, setAccount] = React.useState(loadedAccount);
  const [custodian, setCustodian] = React.useState(inCustodian);
  const [members] = React.useState({
    amount: 0,
  });
  const classes = useCommonStyles();

  const addDeposit = e => doCommitPromise(
    createDepositRequestMutation,
    {
      accountId: account.id,
      amount: Number(members.amount),
      currency,
      custodian,
    },
    () => {
      onClose();
      onAdd && onAdd();
    },
  );

  return (
    <div className={classes.drawerRoot}>
      <DrawerGridItemContainer>
        <DrawerGridItemHeading heading="Add Deposit" />

        <Grid item xs={12}>
          <PopupAccountSelectorField
            fullWidth
            onSelect={a => setAccount(a)}
          />
        </Grid>
        <DrawerGridItemSelectField
          value={currency}
          enumType="Currency"
          onChange={e => setCurrency(e.target.value)}
        />

        <DrawerGridItemTextField
          label="Amount"
          type="number"
          onChange={(e) => {
            members.amount = e.target.value;
          }}
          defaultValue={members.amount}
        />

        <DrawerGridItemSelectField
          label="Custodian"
          enumType="Custodian"
          value={custodian}
          required
          onChange={e => setCustodian(e.target.value)}
        />

        <DrawerGridItemBottomButtonContainer>
          <Grid item>
            <AddButton
              buttonText="Add"
              color="primary"
              onClickPromise={addDeposit}
              disabled={!account}
            />
          </Grid>
        </DrawerGridItemBottomButtonContainer>

      </DrawerGridItemContainer>
    </div>
  );
}
