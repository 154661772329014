import React, { useState } from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Drawer } from '@material-ui/core';
import { useCommonStyles } from '../common/Styles';
import TableFieldMeta from '../../common/TableFieldMeta';
import TopGridContainer from '../common/containers/TopGridContainer';
import { AddButton } from '../common/buttons/BaseButton';
import FeeTemplateForm from './FeeTemplateForm';
import TableButton from '../common/buttons/TableButton';
import { deleteFeeTemplateMutation } from '../../mutations/FeeTemplate';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import { doCommitPromise } from '../../common/commit';
import TablePaginator from '../common/TablePaginator';


function FeeTemplateList(props) {
  const {
    viewer,
    relay,
    filter,
  } = props;
  filter.setRelay(relay);
  const [editedFeeTemplate, setEditedFeeTemplate] = useState(null);
  const classes = useCommonStyles();

  const columns = [
    TableFieldMeta.localId,
    TableFieldMeta.field('name', 'Name'),
    TableFieldMeta.field('description', 'Description'),
    TableFieldMeta.field('type', 'Type'),
    TableFieldMeta.field('currency', 'Currency'),
    TableFieldMeta.moreButtonLink('fee-template', node => node.id),
    {
      id: 'action',
      Header: '',
      accessor: node => node,
      Cell: (row) => {
        const feeTemplate = row.value;
        return (
          <TableButton
            key={`${feeTemplate.id}-${feeTemplate.updatedAt}`}
            onClickPromise={() => doCommitPromise(
              deleteFeeTemplateMutation,
              { feeTemplateId: feeTemplate.id },
              () => filter.update(),
            )}
            color="secondary"
            disabled={(feeTemplate.tenantSet
            && feeTemplate.tenantSet.edges
            && feeTemplate.tenantSet.edges.length > 0) || (
              feeTemplate.tenantexchangefeetemplateSet.edges
              && feeTemplate.tenantexchangefeetemplateSet.edges.length > 0
            )}
          >
            Delete
          </TableButton>
        );
      },
    },
    TableFieldMeta.notes(row => row.value),
  ];

  return (
    <div className={classes.pageRoot}>
      <Drawer
        anchor="right"
        open={!!editedFeeTemplate}
        onClose={() => setEditedFeeTemplate(null)}
      >
        <FeeTemplateForm
          feeTemplate={editedFeeTemplate ? editedFeeTemplate.feeTemplate : null}
          onClose={() => {
            setEditedFeeTemplate(null);
          }}
          onAdd={() => filter.update()}
        />
      </Drawer>
      <TopGridContainer>
        <Grid item>
          <Typography
            variant="h6"
            gutterBottom
          >
            Fee Templates
          </Typography>
        </Grid>
        <Grid item>
          <AddButton
            buttonText="template"
            onClick={e => setEditedFeeTemplate({})}
          />
        </Grid>
      </TopGridContainer>
      <TablePaginator
        columns={columns}
        getDataNode={() => (viewer ? viewer.feeTemplates : null)}
        filter={filter}
      />
    </div>
  );
}

const query = graphql`
  query FeeTemplateListContainerQuery (
    $first: Int,
    $offset: Int,
  ) {
    viewer {
      ...FeeTemplateList_viewer
      @arguments (
        first: $first
        offset: $offset
      )
    }
  }
`;

const container = createRefetchContainer(
  FeeTemplateList,
  {
    viewer: graphql`
      fragment FeeTemplateList_viewer on Query
      @argumentDefinitions(
        offset: {type: Int, defaultValue: 10}
        first: {type: Int, defaultValue: 0}
      ) {
        feeTemplates(
          offset: $offset
          first: $first
#          orderBy: "name"
        ){
          edges {
            node {
              notes {
                id
              }
              id
              name
              description
              type
              createdAt
              updatedAt
              currency
              fees {
                edges {
                  node {
                    id
                    feeType
                    enabled
                  }
                }
              }
              
              tenantexchangefeetemplateSet {
                edges {
                  node {
                    id
                  }
                }
              }
              
              tenantSet {
                edges {
                  node {
                    name
                    id
                  }
                }
              }
            }
          }
          count
        }
      }
    `,
  },
  query,
);

export default withRouter(
  createQueryRendererProgressV2(
    query,
    container,
  ),
);
