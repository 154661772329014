/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type ActionType = "AA" | "AACK" | "AAD" | "ABF" | "AC" | "ACCL" | "ACH" | "ACR" | "ACRO" | "ADIE" | "AEC" | "AEIE" | "AFNC" | "AFNU" | "AIAD" | "AIAE" | "AIAN" | "AIAR" | "AIAS" | "AIAT" | "AIRR" | "AIRS" | "AIRT" | "AM" | "ANC" | "AR" | "ARE" | "AS" | "ATCH" | "AU" | "BAAD" | "BACR" | "BADI" | "BAIG" | "BAPE" | "BAPI" | "BAPR" | "BAUI" | "BAUP" | "BAVE" | "BCC" | "BCU" | "BTIG" | "BTUI" | "CAREMFT" | "CASC" | "CASETFT" | "CASU" | "CIC" | "CISC" | "CISS" | "CISU" | "CIT" | "CIU" | "CIX" | "CTAPR" | "CTBLK" | "CTCAA" | "CTCAN" | "CTREC" | "CTREQ" | "CTUBL" | "CTVFY" | "EEU" | "FIFSI" | "FIFSP" | "FSFSI" | "FSFSP" | "GCBFV" | "GCBRA" | "GCBRC" | "GCBRD" | "GCBRF" | "GCBRI" | "GCBRO" | "GCBRV" | "GCMAC" | "GCMBD" | "GCMCA" | "GCMCD" | "GCMCR" | "GCMEX" | "GCMFL" | "GCMGA" | "GCMRE" | "GCMRI" | "GCMRR" | "GCMRS" | "GCMSA" | "GCMSU" | "GCMTR" | "GCPCO" | "GCPCR" | "GCPFL" | "GCPPO" | "GCPSU" | "GCSAM" | "GCSCA" | "GCSCR" | "GCSDA" | "GCSFD" | "GCSFI" | "GCSPA" | "GCSPC" | "GCSPD" | "GCSPR" | "GCSRE" | "GCSSP" | "GCSSR" | "LLEDN" | "MACC" | "MATC" | "MATU" | "MLTC" | "MLTU" | "OAA" | "OAR" | "OC" | "OCC" | "OCD" | "OM" | "OMCLA" | "OMCLD" | "OMCLN" | "OMCLR" | "OMCNI" | "OMD" | "OMEXP" | "OMOVA" | "OMOVD" | "OMPR" | "OMQA" | "OMRBC" | "OP" | "OPCA" | "OPFC" | "OQ" | "OR" | "ORRCE" | "OTC" | "OX" | "SC" | "SCR" | "SF" | "SR" | "SRA" | "SRF" | "SS" | "SU" | "TBF" | "TCH" | "TCR" | "TIGC" | "TIGD" | "TIGU" | "TISC" | "TISU" | "TPC" | "TPU" | "TRSC" | "TRSU" | "ULMVF" | "ULMVS" | "ULNS" | "ULSC" | "ULUV" | "UMA" | "UMAF" | "UMAP" | "UMAR" | "UMARP" | "UMCP" | "UMCR" | "UMD" | "UMRRC" | "UMURP" | "UUE" | "UUF" | "UUL" | "UUP" | "UUSA" | "UUSD" | "UUU" | "UVE" | "VSPA" | "VSPR" | "VSPU" | "ZBTR" | "ZBTU" | "ZIR" | "ZITR" | "ZIU" | "ZPC" | "ZTP" | "ZTR" | "ZUC" | "ZUU" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserSubscriptionActionLogs_viewer$ref: FragmentReference;
declare export opaque type UserSubscriptionActionLogs_viewer$fragmentType: UserSubscriptionActionLogs_viewer$ref;
export type UserSubscriptionActionLogs_viewer = {|
  +userSubscriptions: ?{|
    +count: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +actionLogs: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +actionType: ActionType,
              +actionTime: any,
              +actionDescription: string,
              +user: ?{|
                +id: string,
                +username: string,
                +email: string,
                +fullName: ?string,
              |},
            |}
          |}>,
          +count: ?number,
        |}
      |}
    |}>,
  |},
  +$refType: UserSubscriptionActionLogs_viewer$ref,
|};
export type UserSubscriptionActionLogs_viewer$data = UserSubscriptionActionLogs_viewer;
export type UserSubscriptionActionLogs_viewer$key = {
  +$data?: UserSubscriptionActionLogs_viewer$data,
  +$fragmentRefs: UserSubscriptionActionLogs_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "count",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "UserSubscriptionActionLogs_viewer",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "first",
      "type": "Int",
      "defaultValue": 10
    },
    {
      "kind": "LocalArgument",
      "name": "offset",
      "type": "Int",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "userSubscriptionId",
      "type": "ID",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "userSubscriptions",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "userSubscriptionId"
        }
      ],
      "concreteType": "UserSubscriptionNodeConnection",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "UserSubscriptionNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "UserSubscriptionNode",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "actionLogs",
                  "storageKey": null,
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "first",
                      "variableName": "first"
                    },
                    {
                      "kind": "Variable",
                      "name": "offset",
                      "variableName": "offset"
                    },
                    {
                      "kind": "Literal",
                      "name": "orderBy",
                      "value": "-actionTime"
                    }
                  ],
                  "concreteType": "ActionLogUserRequestNodeConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "ActionLogUserRequestNodeEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "ActionLogUserRequestNode",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "actionType",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "actionTime",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "actionDescription",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "user",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "UserNode",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "username",
                                  "args": null,
                                  "storageKey": null
                                },
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "email",
                                  "args": null,
                                  "storageKey": null
                                },
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "fullName",
                                  "args": null,
                                  "storageKey": null
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    },
                    (v0/*: any*/)
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '08b5664e3b310f8529edf3bb4da2b493';
module.exports = node;
