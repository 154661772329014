import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { FormHelperText } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { extractKeys } from '../../../common/helpers';
import { muiGridSizes } from '../../../common/constants';
import { EnumValues } from '../EnumValue';
import { ToggleButtonGroupWrapped } from '../innerTab/InnerTabFilterToggleButtonGroup';
import VerticallyCenteredGrid from '../containers/VerticallyCenteredGrid';


function DrawerGripItemToggleButtonGroup(props) {
  const {
    onChange,
    enumType,
    defaultValue,
    label,
    helperText,
    disabled,
    size,
    ...otherProps
  } = props;

  const [sizeProps] = extractKeys(otherProps, muiGridSizes);

  return (
    <VerticallyCenteredGrid item {...sizeProps}>
      <FormControl>
        <InputLabel htmlFor="accountType-helper">{label}</InputLabel>
        <EnumValues
          enumType={enumType}
          render={(enumMeta, enumMetaArray) => (
            <ToggleButtonGroupWrapped
              size={size}
              disabled={disabled}
              enumMetaArray={enumMetaArray}
              defaultValue={defaultValue}
              onChange={onChange}
            />
          )}
        />

        {helperText && (
          <FormHelperText>{helperText}</FormHelperText>
        )}
      </FormControl>
    </VerticallyCenteredGrid>
  );
}

DrawerGripItemToggleButtonGroup.propTypes = {
  disabled: PropTypes.bool,
  xs: PropTypes.number,
};

DrawerGripItemToggleButtonGroup.defaultProps = {
  disabled: false,
  xs: 6,
};

export default DrawerGripItemToggleButtonGroup;
