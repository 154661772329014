import React from 'react';
import { withRouter } from 'react-router-dom';
import { createRefetchContainer, graphql } from 'react-relay';
import { Paper } from '@material-ui/core';
import { useCommonStyles } from '../common/Styles';
import StockChart from './StockChart';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import AdminMetaContext from '../adminPanel/AdminMetaContext';


export const roundTwoDecimals = number => parseFloat(Math.round(number * 10000) / 10000).toFixed(4);

function StockPriceDetails(props) {
  const adminMeta = React.useContext(AdminMetaContext);
  const classes = useCommonStyles();
  const { viewer } = props;
  const fdEdges = viewer.contract.contractFinancialData.edges;
  const seriesData = [
    {
      name: 'Scale Factor',
      yAxis: 0,
      data: fdEdges.map(edge => [
        Math.round(new Date(edge.node.date)),
        parseFloat(roundTwoDecimals(edge.node.scaleFactor)),
      ]),
    },
    {
      name: viewer.contract.currency,
      yAxis: 1,
      data: fdEdges.map(edge => [
        Math.round(new Date(edge.node.date)),
        parseFloat(roundTwoDecimals(edge.node.closingPriceLocal * edge.node.scaleFactor)),
      ]),
    },
  ];

  if (viewer.contract.currency !== adminMeta.baseCurrency) {
    seriesData.push({
      name: adminMeta.baseCurrency,
      yAxis: 1,
      data: fdEdges.map(edge => [
        Math.round(new Date(edge.node.date)),
        parseFloat(roundTwoDecimals(edge.node.closingPriceLocal * edge.node.scaleFactor)),
      ]),
    });
  }

  return (
    <Paper className={classes.innerTabRoot}>
      <div style={{ padding: '20px' }}>
        <StockChart
          seriesData={seriesData}
        />
      </div>
    </Paper>
  );
}


const query = graphql`
  query StockPriceDetailsQuery (
    $contractId: ID!
  ) {
    viewer {
      ...StockPriceDetails_viewer
      @arguments (
        contractId: $contractId
      )
    }
  }
`;


const container = createRefetchContainer(
  StockPriceDetails,
  {
    viewer: graphql`
      fragment StockPriceDetails_viewer on Query
      @argumentDefinitions(
        contractId: {type: "ID!" }
      ) {
        contract(id: $contractId) {
          id
          currency
          contractFinancialData(orderBy: "date", last: 1000) {
            edges {
              node {
                id
                date
                closingPriceLocal
                closingPriceLocal
                scaleFactor
              }
            }
          }
        }
      }
    `,
  },
  query,
);


export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  {},
  ({ contractId }) => ({ contractId }),
));
