import * as React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/core/SvgIcon';
import { Grid } from '@material-ui/core';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { createFragmentContainer, graphql } from 'react-relay';
import Typography from '@material-ui/core/Typography';
import * as PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import TableFieldMeta, { Gid, priceFigureComp } from '../../../common/TableFieldMeta';
import VerticalTable from '../../common/VerticalTable';
import { useCommonStyles } from '../../common/Styles';
import { toLocal } from '../../../helpers/ID';
import Price from '../../common/Price';
import BrokerOrderStatus from '../../common/BrokerOrderStatus';
import ContractLink from '../../common/links/ContractLink';
import TitledWidgetItem from '../../common/TitledWidgetItem';
import DataTableWidget from '../../common/DataTableWidget';
import Percentage from '../../common/Percentage';
import StockOrderInvoiceActions from '../StockOrderInvoiceActions';
import StockOrderAlgorithm from './StockOrderAlgorithm';
import StockOrderBrokerOrderDetails from './StockOrderBrokerOrderDetails';
import { isRecurNull } from '../../../common/helpers';
import ApprovalDetailVerticalTable from '../approval/ApprovalDetailVerticalTable';
import ApprovalActionLogDataTable from '../approval/ApprovalActionLogDataTable';
import StockOrderQuantityStatus from './StockOrderQuantityStatus';
import AdminMetaContext from '../../adminPanel/AdminMetaContext';

function OrderPanelSummaryContent(props) {
  const {
    stockOrder,
  } = props;

  return (
    <Grid container spacing={1}>
      <Grid item>
        <Typography variant="subtitle2">
          {stockOrder.parentOrder && <React.Fragment>&#8618; &nbsp;</React.Fragment>}
          {stockOrder.contract.symbol}
          &nbsp;&bull;&nbsp;
          {stockOrder.contract.exchange.symbol}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2">
          <BrokerOrderStatus stockOrder={stockOrder} />
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2">
          <StockOrderQuantityStatus stockOrder={stockOrder} />
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2">
          {stockOrder.contract.name}
        </Typography>
      </Grid>
    </Grid>);
}

const accountChargesInfo = stockOrder => stockOrder.transactions.edges.reduce((at, vt) => {
  const vtRed = vt.node.transactionaccountchargeSet.edges.reduce(
    (a, v) => {
      const m = v.node.accountchargePtr.accountchargetenantportionSet.edges.reduce(
        (a1, v1) => {
          const tenId = v1.node.tenant.id;
          return {
            tenantInfo: { ...a1.tenantInfo, [tenId]: v1.node.tenant.name },
            tenantQty: ({
              ...a1.tenantQty,
              [tenId]: (a1.tenantQty[tenId] || 0) + v1.node.quantity,
            }),
          };
        },
        { tenantInfo: {}, tenantQty: {} },
      );
      return {
        charges: [...a.charges, { ...v.node, txnId: vt.node.id, tenantQty: m.tenantQty }],
        tenantInfo: { ...a.tenantInfo, ...m.tenantInfo },
      };
    },
    { charges: [], tenantInfo: {} },
  );
  return {
    charges: at.charges.concat(vtRed.charges),
    tenantInfo: { ...at.tenantInfo, ...vtRed.tenantInfo },
  };
}, { charges: [], tenantInfo: {} });

const orderExchangeInfo = (contract, exchanges) => {
  const { exchange: { symbol } } = contract;
  return exchanges.find(item => item.symbol === symbol);
};

OrderPanelSummaryContent.propTypes = {
  stockOrder: PropTypes.object.isRequired,
};

function StockOrderDetails(props) {
  const adminMeta = React.useContext(AdminMetaContext);
  const orderColumns = [
    ['Id', TableFieldMeta.localId.accessor],
    ['Parent ID', node => (node.parentOrder ? toLocal(node.parentOrder.id) : '')],
    ['Price', priceFigureComp('price', ['contract', 'currency'])],
    ['PendingStatus', node => (node.pendingStatus === 'CA' ? 'Pending Cancel' : 'None')],
    ['Total Quantity', node => (node.quantity !== null ? Math.abs(node.quantity) : null)],
    ['Cash Quantity', priceFigureComp('cashQuantity', ['contract', 'currency'])],
    ['Quantity Filled (ToDate)', node => Math.abs(node.quantityToDate)],
    ['Side', 'side'],
    ['Order Type', 'orderType'],
    ['Stop Price', priceFigureComp(['brokerOrder', 'stopPrice'], ['contract', 'currency'])],
    ['Stop Trail - Percent', node => <Percentage ratio={node.brokerOrder.trailStopRatio} />],
    ['Stop Trail - Offset', priceFigureComp(['brokerOrder', 'trailStopOffset'], ['contract', 'currency'])],
    ['Matched', 'matched'],
    ['Blocked Holdings', 'blockedHoldings'],
    ['Blocked Proceeds', priceFigureComp('blockedProceeds', ['contract', 'currency'])],
    ['Blocked Base Charges', priceFigureComp('blockedBaseCharges')],
    ['Blocked Local Charges', priceFigureComp('blockedLocalCharges', ['contract', 'currency'])],
    ['Total Proceeds', priceFigureComp('proceedsToDate', ['contract', 'currency'])],
    ['Total Proceeds Base', priceFigureComp('proceedsToDateBase', () => adminMeta.baseCurrency)],
    ['Custom Commission', priceFigureComp('customCommission', () => adminMeta.baseCurrency)],
    ['Invoice', node => <StockOrderInvoiceActions stockOrder={node} justifyButtons="flex-end" />],
    ['Gid', node => <Gid gid={node.id} />],
  ];

  const contractColumns = [
    ['', node => <ContractLink contract={node.contract} />],
    ['Id', node => toLocal(node.contract.id)],
    ['Currency', ['contract', 'currency']],
    ['Symbol', ['contract', 'symbol']],
    ['Name', ['contract', 'name']],
    ['Industry', ['contract', 'industry']],
    ['Exchange', ['contract', 'exchange', 'name']],
    ['IB Contract ID', ['contract', 'ibConId']],
  ];

  const { stockOrder, exchanges, expanded } = props;
  const classes = useCommonStyles();
  const orderApproval = isRecurNull(stockOrder,
    ['orderPtr', 'orderapprovalcontractSet', 'edges', 0, 'node', 'approval']);

  const accChrInfo = accountChargesInfo(stockOrder);
  const exchange = orderExchangeInfo(stockOrder.contract, exchanges);

  const chargeColumns = [
    TableFieldMeta.field('txnId', 'Txn.',
      { accessor: node => toLocal(node.txnId) }),
    TableFieldMeta.field('feeType', 'Type'),
    TableFieldMeta.localId,
  ];

  Object.keys(accChrInfo.tenantInfo).forEach((tenantId) => {
    chargeColumns.push(TableFieldMeta.priceField(
      `ten${accChrInfo.tenantInfo[tenantId]}`,
      accChrInfo.tenantInfo[tenantId],
      node => node.tenantQty[tenantId],
      () => adminMeta.baseCurrency,
      4,
      {
        reducer: {
          initiator: nodes => 0,
          accumulator: (node, a) => a + (node.value.tenantQty[tenantId] || 0),
          accessor: (nodes, a) => <Price currency={adminMeta.baseCurrency}>{a}</Price>,
        },
      },
    ));
  });

  chargeColumns.push(TableFieldMeta.priceField(
    'chr',
    'Total',
    'amount',
    () => adminMeta.baseCurrency,
    4,
    {
      reducer: {
        initiator: nodes => 0,
        accumulator: (node, a) => a + (node.value.amount || 0),
        accessor: (nodes, a) => <Price currency={adminMeta.baseCurrency}>{a}</Price>,
      },
    },
  ));

  return (
    <ExpansionPanel
      defaultExpanded={expanded}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <OrderPanelSummaryContent
          stockOrder={stockOrder}
        />
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.expandedDetails}>
        <div style={{ width: '100%' }}>
          <Grid container spacing={2}>

            {/* Order Details */}
            <Grid item xs={12} md={6} lg={3}>
              <Paper className={classes.tableHolderRoot}>
                <div className={classes.widgetHeadingContainer}>
                  <Typography
                    component="h6"
                    variant="subtitle2"
                    gutterBottom
                  >
                    Order Details
                  </Typography>
                </div>
                <VerticalTable
                  showHeadings={false}
                  columnMeta={orderColumns}
                  dataNode={stockOrder}
                />

              </Paper>
            </Grid>


            <Grid item xs={12} md={6} lg={3}>
              <Grid container spacing={2}>
                <TitledWidgetItem
                  xs={12}
                  title="Contract Details"
                >
                  <VerticalTable
                    showHeadings={false}
                    columnMeta={contractColumns}
                    dataNode={stockOrder}
                  />
                </TitledWidgetItem>
                <ApprovalDetailVerticalTable
                  xs={12}
                  showSelf
                  orderApproval={orderApproval}
                  title="Approval"
                />
                <StockOrderAlgorithm brokerOrder={stockOrder.brokerOrder} exchange={exchange} />
                <StockOrderBrokerOrderDetails brokerOrder={stockOrder.brokerOrder} />


              </Grid>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Grid container spacing={2}>

                <DataTableWidget
                  xs={12}
                  title="Executions"
                  columnMeta={[
                    TableFieldMeta.priceField('price', 'Price', 'price', 'currency'),
                    TableFieldMeta.dateTimeField('execTime', 'ExecTime', node => node.executionDatetime),
                    TableFieldMeta.quantityField('quantity', 'Quantity'),
                    TableFieldMeta.field('transaction', 'Transaction',
                      {
                        accessor: node => node.transaction && toLocal(node.transaction.id),
                        align: 'right',
                      }),
                  ]}
                  dataNodes={stockOrder.executionSet.edges.map(node => node.node)}
                />

                <DataTableWidget
                  xs={12}
                  title="Charges"
                  columnMeta={chargeColumns}
                  dataNodes={accChrInfo.charges}
                  showFooter
                />

                {
                  orderApproval && (
                    <ApprovalActionLogDataTable
                      title="Approval: Action Logs"
                      xs={12}
                      actionLogs={orderApproval.actionLogs.edges.map(node => node.node)}
                    />
                  )
                }

                <DataTableWidget
                  xs={12}
                  title="Action Log"
                  columnMeta={[
                    TableFieldMeta.dateTimeField('actionTime', 'Action Time', node => node.actionTime),
                    TableFieldMeta.field('user', 'User', { accessor: node => node.user && node.user.fullName }),
                    TableFieldMeta.enum('ActionType', 'Action Type', node => node.actionType),
                    TableFieldMeta.field('actionDescription', 'Action Description'),
                  ]}
                  dataNodes={stockOrder.actionLogs.edges.map(node => node.node)}
                />

                <DataTableWidget
                  xs={12}
                  title="Amendments"
                  columnMeta={[
                    TableFieldMeta.createdAt,
                    TableFieldMeta.fieldCell('quantity', 'Qty', row => (
                      row.value.quantity !== row.value.previousQuantity
                      && (
                        <span>
                          {row.value.previousQuantity}
                          &nbsp;&rarr;&nbsp;
                          {row.value.quantity}
                        </span>
                      )
                    ), { align: 'center' }),
                    TableFieldMeta.fieldCell('limitPrice', 'Lmt', row => (
                      row.value.limitPrice !== row.value.previousLimitPrice
                      && (
                        <span>
                          {row.value.previousLimitPrice}
                          &nbsp;&rarr;&nbsp;
                          {row.value.limitPrice}
                        </span>
                      )
                    ), { align: 'center' }),
                    TableFieldMeta.enum('AmendStatus', 'Status', node => node.status),
                  ]}
                  dataNodes={stockOrder.brokerOrder.brokerorderamendmentSet.edges.map(
                    node => node.node,
                  )}
                />

              </Grid>
            </Grid>

          </Grid>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

export default createFragmentContainer(
  StockOrderDetails,
  {
    stockOrder: graphql`
      fragment StockOrderDetails_stockOrder on StockOrderNode {
        parentOrder {
          id
        }
        id
        orderRef
        status
        pendingStatus
        price
        quantity
        cashQuantity
        side
        createdAt
        updatedAt
        orderType
        quantityToDate
        matched
        blockedHoldings
        blockedProceeds
        blockedBaseCharges
        blockedLocalCharges
        customCommission
        advancedOrderType
        
        actionLogs(orderBy: "actionTime") {
          edges {
            node {
              actionId
              actionType
              actionTime
              actionDescription
              user {
                fullName
              }
            }
          }
        }
        contract {
          id
          currency
          symbol
          name
          industry
          ibConId
          instrumentType
          exchange {
            name
            symbol
          }
        }
        
        account {
          id
          name
          clientaccount {
            id
            accountType
          }
        }
        
        portfolioOrder {
          id
        }
        executionSet {
          edges {
            node {
              transaction {
                id
              }
              price
              proceeds
              executionDatetime
              quantity
              currency
            }
          }
        }
        
        proceedsToDate
        proceedsToDateBase
        brokerOrder {
          id
          twsOrderId
          twsPermId
          cashQuantity
          quantityFilled
          quantityLeft
          stopPrice
          trailStopRatio
          trailStopOffset
          lastSyncTime
          status
          orderRef
          ackedClientOrderId
          brokerSideOrderId
          ibExchange {
            id
            symbol
          }
          
          ...StockOrderAlgorithm_all @relay(mask: false)
          
          brokerorderamendmentSet (orderBy: "sequence") {
            edges {
              node {
                id
                limitPrice
                previousLimitPrice
                quantity
                previousQuantity
                status
                blockingRevised
                createdAt
                updatedAt
              }
            }
          }
        }
        
        orderPtr {
          orderapprovalcontractSet {
            edges {
              node {
                id
                approval {
                  ...ApprovalDetailVerticalTable_approvalDetails
                  @relay(mask: false)

                  ...ApprovalActionLogDataTable_actionLogs
                  @relay(mask: false)
                }
              }
            }
          }
        }
        
        ...StockOrderInvoiceActions_invoice @relay(mask: false)
        
        transactions {
          edges {
            node {
              id
              commission
              tax
              transactionaccountchargeSet {
                edges {
                  node {
                    id
                    amount
                    currency
                    feeType
                    accountchargePtr {
                      accountchargetenantportionSet {
                        edges {
                          node {
                            id
                            quantity
                            tenant {
                              name
                              id
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
);

StockOrderDetails.propTypes = {
  stockOrder: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
};

StockOrderDetails.defaultProps = {
  expanded: false,
};
