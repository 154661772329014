/**
 * @flow
 * @relayHash 056e5accea2052cda705d493d31e55fc
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CacheSource = "DP" | "DPE" | "FF" | "OP" | "%future added value";
export type ContractIbStatus = "CO" | "EX" | "TR" | "%future added value";
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
export type InstrumentType = "CASH" | "STK" | "WAR" | "%future added value";
export type ContractDetailsQueryVariables = {|
  contractId: string
|};
export type ContractDetailsQueryResponse = {|
  +viewer: ?{|
    +contracts: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +symbol: string,
          +currency: Currency,
          +imageUrl: ?string,
          +createdAt: any,
          +updatedAt: any,
          +ibStatus: ContractIbStatus,
          +ibConId: number,
          +instrumentType: InstrumentType,
          +name: ?string,
          +exchange: {|
            +symbol: string
          |},
          +latestPriceBase: ?number,
          +latestPriceLocal: ?number,
          +latestPriceDate: ?any,
          +latestPriceTime: ?any,
          +priceIncrements: ?$ReadOnlyArray<?{|
            +increment: ?any,
            +lowEdge: ?any,
          |}>,
          +priceCashInfo: ?{|
            +timeSet: ?any,
            +timeOfPrice: ?any,
            +price: ?number,
            +source: ?CacheSource,
            +currency: ?string,
          |},
          +contractmarketdatarequestSet: {|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string
              |}
            |}>
          |},
          +fsContract: ?{|
            +id: string,
            +isin: ?string,
            +name: ?string,
            +country: ?string,
            +industry: ?string,
            +sedol: string,
            +sector: ?string,
            +lotSize: number,
            +currency: Currency,
            +factsetlatestcontractfinancialdata: ?{|
              +date: any,
              +closingPriceLocal: ?number,
            |},
          |},
        |}
      |}>
    |}
  |}
|};
export type ContractDetailsQuery = {|
  variables: ContractDetailsQueryVariables,
  response: ContractDetailsQueryResponse,
|};
*/


/*
query ContractDetailsQuery(
  $contractId: ID!
) {
  viewer {
    contracts(id: $contractId) {
      edges {
        node {
          id
          symbol
          currency
          imageUrl
          createdAt
          updatedAt
          ibStatus
          ibConId
          instrumentType
          name
          exchange {
            symbol
            id
          }
          latestPriceBase
          latestPriceLocal
          latestPriceDate
          latestPriceTime
          priceIncrements {
            increment
            lowEdge
          }
          priceCashInfo {
            timeSet
            timeOfPrice
            price
            source
            currency
          }
          contractmarketdatarequestSet {
            edges {
              node {
                id
              }
            }
          }
          fsContract {
            id
            isin
            name
            country
            industry
            sedol
            sector
            lotSize
            currency
            factsetlatestcontractfinancialdata {
              date
              closingPriceLocal
              id
            }
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "contractId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "contractId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "symbol",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "currency",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "imageUrl",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ibStatus",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ibConId",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "instrumentType",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "latestPriceBase",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "latestPriceLocal",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "latestPriceDate",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "latestPriceTime",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "priceIncrements",
  "storageKey": null,
  "args": null,
  "concreteType": "PriceIncrement",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "increment",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lowEdge",
      "args": null,
      "storageKey": null
    }
  ]
},
v17 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "priceCashInfo",
  "storageKey": null,
  "args": null,
  "concreteType": "PriceCacheInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "timeSet",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "timeOfPrice",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "price",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "source",
      "args": null,
      "storageKey": null
    },
    (v4/*: any*/)
  ]
},
v18 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "contractmarketdatarequestSet",
  "storageKey": null,
  "args": null,
  "concreteType": "ContractMarketDataRequestNodeConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "ContractMarketDataRequestNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "ContractMarketDataRequestNode",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ]
        }
      ]
    }
  ]
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isin",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "country",
  "args": null,
  "storageKey": null
},
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "industry",
  "args": null,
  "storageKey": null
},
v22 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "sedol",
  "args": null,
  "storageKey": null
},
v23 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "sector",
  "args": null,
  "storageKey": null
},
v24 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lotSize",
  "args": null,
  "storageKey": null
},
v25 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "date",
  "args": null,
  "storageKey": null
},
v26 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "closingPriceLocal",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ContractDetailsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contracts",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "ContractNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ContractNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ContractNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "exchange",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ExchangeNode",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/)
                        ]
                      },
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "fsContract",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "FactsetContractNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v19/*: any*/),
                          (v11/*: any*/),
                          (v20/*: any*/),
                          (v21/*: any*/),
                          (v22/*: any*/),
                          (v23/*: any*/),
                          (v24/*: any*/),
                          (v4/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "factsetlatestcontractfinancialdata",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "LatestContractFinancialDataNode",
                            "plural": false,
                            "selections": [
                              (v25/*: any*/),
                              (v26/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ContractDetailsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contracts",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "ContractNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ContractNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ContractNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "exchange",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ExchangeNode",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v2/*: any*/)
                        ]
                      },
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "fsContract",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "FactsetContractNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v19/*: any*/),
                          (v11/*: any*/),
                          (v20/*: any*/),
                          (v21/*: any*/),
                          (v22/*: any*/),
                          (v23/*: any*/),
                          (v24/*: any*/),
                          (v4/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "factsetlatestcontractfinancialdata",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "LatestContractFinancialDataNode",
                            "plural": false,
                            "selections": [
                              (v25/*: any*/),
                              (v26/*: any*/),
                              (v2/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ContractDetailsQuery",
    "id": null,
    "text": "query ContractDetailsQuery(\n  $contractId: ID!\n) {\n  viewer {\n    contracts(id: $contractId) {\n      edges {\n        node {\n          id\n          symbol\n          currency\n          imageUrl\n          createdAt\n          updatedAt\n          ibStatus\n          ibConId\n          instrumentType\n          name\n          exchange {\n            symbol\n            id\n          }\n          latestPriceBase\n          latestPriceLocal\n          latestPriceDate\n          latestPriceTime\n          priceIncrements {\n            increment\n            lowEdge\n          }\n          priceCashInfo {\n            timeSet\n            timeOfPrice\n            price\n            source\n            currency\n          }\n          contractmarketdatarequestSet {\n            edges {\n              node {\n                id\n              }\n            }\n          }\n          fsContract {\n            id\n            isin\n            name\n            country\n            industry\n            sedol\n            sector\n            lotSize\n            currency\n            factsetlatestcontractfinancialdata {\n              date\n              closingPriceLocal\n              id\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0d16c32b4265d72a7db157c4fa76d9ab';
module.exports = node;
