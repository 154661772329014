import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { fromGlobalId } from '../../helpers/ID';
import MoreButton from '../common/buttons/MoreButton';
import { commonStyles } from '../common/Styles';
import PlainLink from '../common/PlainLink';
import Price from '../common/Price';
import AdminMetaContext from '../adminPanel/AdminMetaContext';

const TenantAccountsTab = (props) => {
  const { classes, tenant } = props;
  const { tenantAccounts } = tenant;

  const adminMeta = React.useContext(AdminMetaContext);

  return (
    <Paper className={classes.innerTabRoot}>
      <div className={classes.tableHolderRoot}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Account Name</TableCell>
              <TableCell>Account ID</TableCell>
              <TableCell align="right">{`Actual (${adminMeta.baseCurrency})`}</TableCell>
              <TableCell align="right">{`Available (${adminMeta.baseCurrency})`}</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              tenantAccounts.edges.map(
                tenantAccount => (
                  <TableRow key={tenantAccount.node.id}>
                    <TableCell>{tenantAccount.node.name}</TableCell>
                    <TableCell>{fromGlobalId(tenantAccount.node.id)[1]}</TableCell>
                    <TableCell align="right">
                      <Price>{tenantAccount.node.accountPtr.actualCashBase}</Price>
                    </TableCell>
                    <TableCell align="right">
                      <Price>{tenantAccount.node.accountPtr.availableCashBase}</Price>
                    </TableCell>
                    <TableCell>
                      <PlainLink to={`/account/${fromGlobalId(tenantAccount.node.id)[1]}`}>
                        <MoreButton />
                      </PlainLink>
                    </TableCell>
                  </TableRow>
                ),
              )
            }
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
};

TenantAccountsTab.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(commonStyles)(TenantAccountsTab);
